import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { A, IntdevuiThemeProvider } from 'intdev-ui';
import UserTooltipContainer from '../common/components/UserTooltipContainer';
import { withoutParent, replaceTag } from './extensions';


$.fn.withoutParent = withoutParent;
$.fn.replaceTag = replaceTag;


const replaceMentions = () => {
    let mentions = $('a.user-inline-link').withoutParent('.user-popover-container').withoutParent('.redactor-box');
    for (let i = 0; i < mentions.length; i++) {
        const mention = mentions[i];
        $(mention).replaceTag('<span>');
    }

    mentions = $('span.user-inline-link').withoutParent('.user-popover-container').withoutParent('.redactor-box');
    for (let i = 0; i < mentions.length; i++) {
        const mention = mentions[i];
        const regex = /\/users\/([\w.-]+)\//g;
        const href = $(mention).attr('href');
        const username = regex.exec(href)?.[1];
        const className = mention.className;
        if (username) {
            ReactDOM.render(
                <IntdevuiThemeProvider styleRoot={ { display: 'inline' } }>
                    <UserTooltipContainer
                        username={ username }
                        wrapper="span"
                        horizontal="middle"
                    >
                        <A className={ className } href={ href }>
                            { mention.textContent }
                        </A>
                    </UserTooltipContainer>
                </IntdevuiThemeProvider>,
                mention,
            );
            mention.removeAttribute('href');
            mention.removeAttribute('class');
        }
    }
};


replaceMentions();
$(() => setInterval(replaceMentions, 2000));
