import $ from 'jquery';


export function withoutParent(parentSelector) {
    function withoutParentPlugin() {
        return $(this).closest(parentSelector).length === 0;
    }
    return this.filter(withoutParentPlugin);
}


export function replaceTag(tag) {
    function replaceTagPlugin(currentElem, newTagObj) {
        const $currentElem = $(currentElem);
        const $newTag = $(newTagObj).clone();
        $newTag.addClass($currentElem[0].className);
        $newTag.attr('href', $currentElem[0].href);
        $currentElem.wrapAll($newTag);
        $currentElem.contents().unwrap();
        return this;
    }
    return replaceTagPlugin(this, tag);
}
